<template>
	<div>
		<div class="newsList" v-if="windowWidth>=768">
			<div class="nl_div" v-for="(item,index) in list" :key="index" @click="jump(item)">
				<el-image class="nl_div_image" :src="item.Image" :fit="'cover'"></el-image>
				
				<div class="nl_div_content">
					<div class="nl_div_ct_title">{{item.articleTitle}}</div>
					<div class="nl_div_ct_content">{{setText(item.articleContent)}}</div>
				</div>
				
			</div>
		</div>
		
		<div class="newsList2" v-if="windowWidth<=767">
			<div class="nl_div2" v-for="(item,index) in list" :key="index" @click="jump(item)">
				<el-image class="nl_div_image2" :src="item.Image" :fit="'cover'"></el-image>
				
				<div class="nl_div_content2">
					<div class="nl_div_ct_title2">{{item.articleTitle}}</div>
					<div class="nl_div_ct_content2">{{setText(item.articleContent)}}</div>
				</div>
				
			</div>
		</div>
	</div>
</template>

<script>
	import {dataInJs} from './data.js'
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				list:dataInJs(),
			}
		},
		
		created() {
			
		},
		
		mounted() {
			this.list = dataInJs(this.$i18n.locale)
		},
		
		watch:{
			//双向绑定语言切换
			'$i18n.locale'(val) {
				if (this.$i18n.locale == 'cn') {
					this.list = dataInJs('cn')//资讯中心列表
				} else {
					this.list = dataInJs('en')//资讯中心列表
				}
			}
		},
		
		computed:{
			
		},
		
		methods:{
			// ----------------------去除标签-------------------------------------
			setText(html) {
				return html.replace(/<(p|div)[^>]*>(<br\/?>|&nbsp;)<\/\1>/gi, '\n')
				.replace(/<br\/?>/gi, '\n')
				.replace(/<[^>/]+>/g, '')
				.replace(/(\n)?<\/([^>]+)>/g, '')
				.replace(/\u00a0/g, ' ')
				.replace(/&nbsp;/g, ' ')
				.replace(/<img[^>]+src\\s*=\\s*['\"]([^'\"]+)['\"][^>]*>/g, '')
				.replace(/<\/?(img|table|br)[^>]*>/g, "")   // 去除图片和表格
				.replace(/<\/?(a)[^>]*>/g, "")			//  去除a标签
			},
			
			//---------------------跳转详情---------------------------------
			jump:function(item){
				this.$router.push({
					path: '/details',
					query: item
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.newsList{
			padding: 80px 200px;
		}
		
		.nl_div{
			padding: 20px;
			background: #f7f7f7;
			margin-bottom: 30px;
			display: flex;
			align-items: center;
			width: 100%;
			
			.nl_div_image{
				height: 240px;
				width: 30%;
			}
			
			.nl_div_content{
				padding: 0 30px;
				width: 70%;
				cursor: pointer;
				
				.nl_div_ct_title{
					font-size: 22px;
					line-height: 28px;
					color: #1d1d1d;
					transition: all 0.5s ease;
				}
				
				.nl_div_ct_content{
					margin-top: 24px;
					font-size: 14px;
					color: #999999;
					line-height: 24px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;
				}
			}
		}
		
		.nl_div_content:hover .nl_div_ct_title{
			color: #1074bc;
		}
	}
	
	@media only screen and (max-width:1920px) {
		.newsList{
			padding: 80px 200px;
		}
		
		.nl_div{
			padding: 20px;
			background: #f7f7f7;
			margin-bottom: 30px;
			display: flex;
			align-items: center;
			width: 100%;
			
			.nl_div_image{
				height: 240px;
				width: 30%;
			}
			
			.nl_div_content{
				padding: 0 30px;
				width: 70%;
				cursor: pointer;
				
				.nl_div_ct_title{
					font-size: 22px;
					line-height: 28px;
					color: #1d1d1d;
					transition: all 0.5s ease;
				}
				
				.nl_div_ct_content{
					margin-top: 24px;
					font-size: 14px;
					color: #999999;
					line-height: 24px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;
				}
			}
		}
		
		.nl_div_content:hover .nl_div_ct_title{
			color: #1074bc;
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.newsList{
			padding:60px;
		}
		
		.nl_div{
			padding: 20px;
			background: #f7f7f7;
			margin-bottom: 30px;
			display: flex;
			align-items: center;
			width: 100%;
			
			.nl_div_image{
				height: 240px;
				width: 30%;
			}
			
			.nl_div_content{
				padding: 0 30px;
				width: 70%;
				cursor: pointer;
				
				.nl_div_ct_title{
					font-size: 22px;
					line-height: 28px;
					color: #1d1d1d;
					transition: all 0.5s ease;
				}
				
				.nl_div_ct_content{
					margin-top: 24px;
					font-size: 14px;
					color: #999999;
					line-height: 24px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;
				}
			}
		}
		
		.nl_div_content:hover .nl_div_ct_title{
			color: #1074bc;
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.newsList{
			padding: 40px;
		}
		
		.nl_div{
			padding: 20px;
			background: #f7f7f7;
			margin-bottom: 30px;
			display: flex;
			align-items: center;
			width: 100%;
			
			.nl_div_image{
				height: 240px;
				width: 30%;
			}
			
			.nl_div_content{
				padding: 0 30px;
				width: 70%;
				cursor: pointer;
				
				.nl_div_ct_title{
					font-size: 22px;
					line-height: 28px;
					color: #1d1d1d;
					transition: all 0.5s ease;
				}
				
				.nl_div_ct_content{
					margin-top: 24px;
					font-size: 14px;
					color: #999999;
					line-height: 24px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;
				}
			}
		}
		
		.nl_div_content:hover .nl_div_ct_title{
			color: #1074bc;
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.newsList{
			padding:20px;
		}
		
		.nl_div{
			padding: 20px;
			background: #f7f7f7;
			margin-bottom: 30px;
			display: flex;
			align-items: center;
			width: 100%;
			
			.nl_div_image{
				height: 240px;
				width: 30%;
			}
			
			.nl_div_content{
				padding: 0 30px;
				width: 70%;
				cursor: pointer;
				
				.nl_div_ct_title{
					font-size: 22px;
					line-height: 28px;
					color: #1d1d1d;
					transition: all 0.5s ease;
				}
				
				.nl_div_ct_content{
					margin-top: 24px;
					font-size: 14px;
					color: #999999;
					line-height: 24px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;
				}
			}
		}
		
		.nl_div_content:hover .nl_div_ct_title{
			color: #1074bc;
		}
	}
	
	@media only screen and (max-width:767px) {
		
			.newsList2{
				padding:20px;
			}
			
			.nl_div2{
				padding: 20px;
				background: #f7f7f7;
				margin-bottom: 30px;
				// display: flex;
				// align-items: center;
				width: 100%;
				
				.nl_div_image2{
					height: 240px;
					width: 100%;
				}
				
				.nl_div_content2{
					// padding: 0 30px;
					width: 100%;
					cursor: pointer;
					
					.nl_div_ct_title2{
						margin-top: 10px;
						font-size: 22px;
						line-height: 28px;
						color: #1d1d1d;
						transition: all 0.5s ease;
					}
					
					.nl_div_ct_content2{
						margin-top: 10px;
						font-size: 14px;
						color: #999999;
						line-height: 24px;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 3;
						-webkit-box-orient: vertical;
					}
				}
			}
			
			.nl_div_content:hover .nl_div_ct_title{
				color: #1074bc;
			}
	}
</style>